import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Steps, useAppContext } from 'state/context/AppContext';
import LoadingScreen from 'screens/Loading';
import OpeningScreen from 'screens/Opening';
import ActionScreen from 'screens/Action';
import ClaimActionScreen from 'screens/ClaimAction';
import ResultScreen from 'screens/Result';

const App = () => {
  const appContext = useAppContext();

  const renderPage = () => {
    if (appContext.appLoading) {
      return (
        <LoadingScreen />
      );
    }

    if (appContext.step === Steps.OPENING) {
      return (
        <OpeningScreen />
      );
    }

    if (appContext.step === Steps.ACTION) {
      return (
        <ActionScreen />
      );
    }

    if (appContext.step === Steps.CLAIM_ACTION) {
      return (
        <ClaimActionScreen />
      );
    }

    if (appContext.step === Steps.RESULT) {
      return (
        <ResultScreen />
      );
    }

    return (
      <div>Default Screen</div>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:campaignId/g/:groupId" element={renderPage()} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
