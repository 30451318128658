import { Block } from 'baseui/block';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { useAppContext } from 'state/context/AppContext';

const AppHeading = () => {
  const appContext = useAppContext();

  return (
    <Block marginTop="scale500">
      <AspectRatioBox aspectRatio={3 / 1} width="scale4800">
        <AspectRatioBoxBody
          display="flex"
          alignItems="center"
          justifyContent="center"
          as="img"
          src={appContext?.campaign?.logoImage}
        />
      </AspectRatioBox>
    </Block>
  );
};

export default AppHeading;
