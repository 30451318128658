import { Block } from 'baseui/block';
import { Paragraph3 } from 'baseui/typography';
import { StyledLink } from 'baseui/link';

const AppFooter = ({ position = 'absolute' }) => (
  <Block position={position} bottom="scale300">
    <Paragraph3 $style={{ textAlign: 'center' }}>
      A product of&nbsp;
      <StyledLink href="https://rangoon.tech" target="_blank" rel="noopener noreferrer">
        <b>
          rangoon.tech
        </b>.
      </StyledLink>
    </Paragraph3>
  </Block>
);

export default AppFooter;
