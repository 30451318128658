import { Block } from 'baseui/block';
import { StyledSpinnerNext } from 'baseui/spinner';
import AppLayout from 'components/Layout';

const LoadingScreen = () => {
  return (
    <AppLayout>
        <Block height="100%" display="flex" flexDirection="column" justifyContent="center">
          <StyledSpinnerNext />
        </Block>
      </AppLayout>
  );
}

export default LoadingScreen;
