import ApiService from './ApiService';

const Service = {
  fetchCampaignData: async (campaignId) => {
    const { data } = await ApiService.get(`/p/campaign/${campaignId}`);
    return data;
  },

  createNewUser: async (doc) => {
    const { data } = await ApiService.post('/p/user', doc);
    return data;
  },
  
  createSession: async ({ campaignId, userId }) => {
    const { data } = await ApiService.post('/p/session', { campaignId, userId });
    return data;
  },

  getSession: async (sessionId) => {
    const { data } = await ApiService.get(`/p/session/${sessionId}`);
    return data;
  },

  updateStep: async (sessionId, step) => {
    const { data } = await ApiService.put(`/p/session/${sessionId}/status`, { status: step });
    return data;
  },

  fetchItems: async (groupId) => {
    const { data } = await ApiService.get(`/p/items/${groupId}`);
    return data;
  },

  drawItem: async (groupId, userId, sessionId) => {
    const { data } = await ApiService.post('/p/items/draw', { groupId, userId, sessionId });
    return data;
  },

  createFormEntry: async (doc) => {
    const { data } = await ApiService.post('/p/form_entry', doc);
    return data;
  },
};

export default Service;
