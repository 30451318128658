import { createContext, useContext, useEffect, useState } from 'react';
import Service from 'services';

export const Steps = {
  OPENING: 'opened', // opened
  ACTION: 'playing', // playing
  CLAIM_ACTION: 'played', // played
  RESULT: 'claimed', // claimed
};

const AppDataContext = createContext({
  step: Steps.OPENING,
  setStep: () => { },
  updateStep: () => { },

  appLoading: true,

  user: {},

  campaign: {},
  setCampaign: () => { },

  session: {},
  setSession: () => {  },
});

export const useAppContext = () => useContext(AppDataContext);

const AppDataProvider = ({ children }) => {
  const [appLoading, setAppLoading] = useState(true);
  const [step, setStep] = useState(Steps.OPENING);
  const [user, setUser] = useState({});
  const [campaign, setCampaign] = useState({});
  const [session, setSession] = useState({});

  useEffect(() => {
    const process = async (cid) => {
      // Create user if not exists
      let uid = localStorage.getItem('uid');
      if (!uid) {
        const user = await Service.createNewUser({ type: 'token' });
        uid = user?._id;
        localStorage.setItem('uid', uid);
      }
      setUser({ userId: uid });

      // Fetch campaign information
      const campaignData = await Service.fetchCampaignData(cid);
      setCampaign(campaignData);

      // Fetch token information
      const tid = localStorage.getItem('tid');
      if (!tid) {
        const sess = await Service.createSession({ campaignId: cid, userId: uid });
        localStorage.setItem('tid', sess?.uniqueCode);
        setStep(sess?.status);
        setSession(sess);
      } else {
        const sess = await Service.getSession(tid);
        setStep(sess?.status);
        setSession(sess);
      }

      setAppLoading(false);
    };


    const [, campaignId] = window.location.pathname.split('/');

    process(campaignId);
  }, []);

  const updateStep = async (newStep) => {
    setStep(newStep);
    await Service.updateStep(session?.uniqueCode, newStep);
  };

  return (
    <AppDataContext.Provider
      value={{
        appLoading,
        step,
        setStep,
        updateStep,
        campaign,
        setCampaign,
        user,
        session,
        setSession,
      }}
    >
      {children}
    </AppDataContext.Provider>
  )
};

export default AppDataProvider;
