import { Block } from 'baseui/block';
import { colors } from 'baseui/tokens';

const AppLayout = ({ children }) => {
  return (
    <Block
      display="flex"
      justifyContent="center"
      backgroundColor={colors.platinum50}
      overflow="scroll"
    >
      <Block
        maxWidth="500px"
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {children}
      </Block>
    </Block>
  );
};

export default AppLayout;
