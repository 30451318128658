import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import AppLayout from 'components/Layout';
import AppHeading from 'components/AppHeading';
import AppFooter from 'components/AppFooter';
import { Steps, useAppContext } from 'state/context/AppContext';
import Service from 'services';

const ClaimActionScreen = () => {
  const appContext = useAppContext();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: '', email: '', phone: '',
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    const payload = {
      userId: appContext?.user?.userId,
      campaignId: appContext?.campaign?._id,
      resultId: appContext?.session?._id,
      data,
    };
    await Service.createFormEntry(payload);
    setLoading(false);
    gotoResult();
  };

  const gotoResult = () => {
    appContext.updateStep(Steps.ACTION);
  };

  return (
    <AppLayout>
      <AppHeading />
      <Block as="form" onSubmit={handleSubmit(onSubmit)} width="85%" height="100%" display="flex" flexDirection="column" alignItems="center" marginTop="scale800">
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Name is required',
          }}
          render={({ field }) => (
            <FormControl label="Name *" error={errors?.name?.message}>
              <Input {...field} placeholder="Enter your name" />
            </FormControl>
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'Phone no. is required.'
          }}
          render={({ field }) => (
            <FormControl label="Phone No. *" error={errors?.phone?.message}>
              <Input {...field} placeholder="Enter your phone number" />
            </FormControl>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormControl label="Email" error={errors?.email?.message}>
              <Input {...field} placeholder="Enter your email" />
            </FormControl>
          )}
        />

        <Block marginTop="scale800" />
        <Button
          type="submit"
          kind={KIND.primary}
          size={SIZE.default}
          shape={SHAPE.default}
          isLoading={loading}
          overrides={{
            Root: {
              style: {
                width: '100%',
              },
            },
          }}
        >
          Submit
        </Button>
      </Block>
      <AppFooter position="relative" />
    </AppLayout >
  );
};

export default ClaimActionScreen;
