import { Block } from 'baseui/block';
import { H4, Paragraph2 } from 'baseui/typography';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import { StyledLink } from 'baseui/link';
import { StyledSpinnerNext } from 'baseui/spinner';
import AppLayout from 'components/Layout';
import AppHeading from 'components/AppHeading';
import AppFooter from 'components/AppFooter';
import { Steps, useAppContext } from 'state/context/AppContext';

const OpeningScreen = () => {
  const appContext = useAppContext();

  const gotoAction = () => {
    appContext.updateStep(Steps.CLAIM_ACTION);
  };

  if (appContext.appLoading) {
    return (
      <AppLayout>
        <Block height="100%" display="flex" flexDirection="column" justifyContent="center">
          <StyledSpinnerNext />
        </Block>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <AppHeading />
      <Block marginTop="scale3200">
        <H4
          marginTop="scale400"
          paddingLeft="scale600"
          paddingRight="scale600"
          $style={{ textAlign: 'center' }}
        >
          {appContext?.campaign?.title}
        </H4>
        <Block marginTop="scale500" display="flex" flexDirection="column" alignItems="center">
          <Button
            onClick={gotoAction}
            kind={KIND.primary}
            size={SIZE.default}
            shape={SHAPE.default}
            overrides={{
              Root: {
                style: {
                  width: '85%',
                  // backgroundColor: theme.colors.backgroundAccent,
                  // ':hover': {
                  //   backgroundColor: theme.colors.accent300,
                  // }
                },
              },
            }}
          >
            Continue
          </Button>
          <Paragraph2 marginTop="scale800">
            Check our&nbsp;
            <StyledLink href="https://rangoon.tech" target="_blank" rel="noopener noreferrer">
              Terms & Conditions.
            </StyledLink>
          </Paragraph2>
        </Block>
      </Block>
      <AppFooter />
    </AppLayout>
  );
};

export default OpeningScreen;
