import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import Winwheel from 'winwheel';
import AppLayout from 'components/Layout';
import AppHeading from 'components/AppHeading';
import AppFooter from 'components/AppFooter';
import { Steps, useAppContext } from 'state/context/AppContext';
import Service from 'services';

// const groupId = '611b8229ee2793de65bd4ab9';
// const userId = '611b8229ee2793de65bd4abb';

const fillColors = ['#eae56f', '#89f26e', '#7de6ef', '#e7706f'];

const transformItems = (item, i) => ({
  text: item?.name,
  fillStyle: fillColors[i % 4],
});

const ActionScreen = () => {
  const appContext = useAppContext();
  const { groupId } = useParams();

  const [wheel, setWheel] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinning, setSpinning] = useState(false);
  const [isDrawn, setIsDrawn] = useState(false);
  const [wonItem, setWonItem] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemData = await Service.fetchItems(groupId);
        setItems(itemData);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!wheel && items.length) {
      const theWheel = new Winwheel({
        numSegments: items.length,
        outerRadius: 150,
        textFontSize: 16,
        responsive: true,
        segments: items.map((item, i) => transformItems(item, i)),
        animation: {
          type: 'spinToStop',
          duration: 5,
          spins: 8,
          callbackFinished: (indicatedSegment) => console.log(indicatedSegment),
        }
      });

      setWheel(theWheel);
    }
  }, [wheel, items]);

  const calculatePrize = async () => {
    setSpinning(true);

    const userId = appContext?.user?.userId;
    const sessionId = appContext?.session?._id;

    const result = await Service.drawItem(groupId, userId, sessionId);
    console.log(result);
    const itemIdx = items.findIndex((item) => item._id === result?.chosenItem?._id);
    const oneSliceAngle = Math.floor(360 / items.length);

    let stopAt = ((itemIdx * oneSliceAngle) + Math.floor((Math.random() * 39)))
    wheel.animation.stopAngle = stopAt;
    wheel.startAnimation();

    setTimeout(() => handlePrizeDrawn(result?.chosenItem), 5000);
  }

  const handlePrizeDrawn = (item) => {
    setSpinning(false);
    setIsDrawn(true);
    setWonItem(item);
    appContext.setSession({
      ...appContext.session,
      drawnAt: new Date().toISOString(),
      item,
    });
  };

  const gotoForm = () => {
    appContext.updateStep(Steps.RESULT);
  };

  return (
    <AppLayout>
      <AppHeading />
      <Block width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" marginTop="scale800">
        {isDrawn && (
          <Notification
            kind={NotificationKind.positive}
            overrides={{
              Body: {
                style: { width: '80%' }
              }
            }}
          >
            You have won "{wonItem?.name}".
            <br />
            Please claim your reward by finishing the form in the next step.
          </Notification>
        )}

        <Block display="flex" flexDirection="column" alignItems="center" marginBottom="scale1000">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <img src='/pointer.png' alt="pointer" width="40" height="40" style={{ zIndex: 10, marginBottom: -20, marginTop: 20 }} />
          )}
          <canvas width="300" height="300" id="canvas">
            <p align="center">Sorry, your browser doesn't support canvas. Please try
              another.</p>
          </canvas>
        </Block>

        {!loading && !isDrawn ? (
          <Button
            onClick={calculatePrize}
            kind={KIND.primary}
            size={SIZE.default}
            shape={SHAPE.default}
            disabled={spinning}
            overrides={{
              Root: {
                style: {
                  width: '50%',
                  // backgroundColor: theme.colors.backgroundAccent,
                  // ':hover': {
                  //   backgroundColor: theme.colors.accent300,
                  // }
                },
              },
            }}
          >
            Spin
          </Button>
        ) : null}

        {isDrawn ? (
          <Button
            onClick={gotoForm}
            kind={KIND.primary}
            size={SIZE.default}
            shape={SHAPE.default}
            overrides={{
              Root: {
                style: { width: '50%' },
              },
            }}
          >
            Finish
          </Button>
        ) : null}
      </Block>
      <AppFooter position="relative" />
    </AppLayout>
  );
};

export default ActionScreen;
