import { useState } from 'react';
import format from 'date-fns/format';
import { Block } from 'baseui/block';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { ListItem, ListItemLabel } from 'baseui/list';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import {
  Modal, ModalHeader, ModalBody, SIZE as ModalSize, ROLE,
} from "baseui/modal";
import QRCode from 'react-qr-code';
import AppLayout from 'components/Layout';
import AppHeading from 'components/AppHeading';
import AppFooter from 'components/AppFooter';
import { Steps, useAppContext } from 'state/context/AppContext';
import Service from 'services';

const ResultScreen = () => {
  const appContext = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const restart = async () => {
    setLoading(true);

    try {
      await Service.updateStep(appContext?.session?.uniqueCode, Steps.OPENING);
      setModalOpen(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <AppHeading />
      <Block width="85%" height="100%" display="flex" flexDirection="column" alignItems="center" marginTop="scale500">
        <Notification
          kind={NotificationKind.info}
          overrides={{
            Body: {
              style: { width: '100%' }
            }
          }}
        >
          You have successfully claimed the prize token.
          <br />
          Please save a screenshot of this page for proof.
        </Notification>

        <AspectRatioBox aspectRatio={1 / 1} width="200px" marginTop="scale800">
          <AspectRatioBoxBody
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <QRCode value={appContext?.session?.uniqueCode} size={200} />
          </AspectRatioBoxBody>
        </AspectRatioBox>

        <ul style={{ width: '100%', paddingLeft: 0 }}>
          <ListItem>
            <ListItemLabel description={appContext?.session?.uniqueCode}>
              Token ID
            </ListItemLabel>
          </ListItem>
          <ListItem>
            <ListItemLabel description={appContext?.session?.item?.name}>
              Prize
            </ListItemLabel>
          </ListItem>
          <ListItem>
            <ListItemLabel description={format(new Date(appContext?.session?.drawnAt), 'd MMM u h:mm aaa')}>
              {/* 15 Jan 2022 3:05pm */}
              Drawn Time
            </ListItemLabel>
          </ListItem>
          <ListItem>
            <ListItemLabel description={appContext?.campaign?.title}>
              Campaign Name
            </ListItemLabel>
          </ListItem>
        </ul>

        <Button
          onClick={restart}
          kind={KIND.secondary}
          size={SIZE.default}
          shape={SHAPE.default}
          isLoading={loading}
          overrides={{
            Root: {
              style: { width: '50%' },
            },
          }}
        >
          Restart Session (demo only)
        </Button>
      </Block>
      <AppFooter position="relative" />

      <Modal
        closeable={false}
        isOpen={modalOpen}
        animate
        autoFocus
        size={ModalSize.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Session reset</ModalHeader>
        <ModalBody>Please refresh the page to restart.</ModalBody>
      </Modal>
    </AppLayout >
  );
};

export default ResultScreen;
